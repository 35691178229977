.parentContainer {
  /* max-height: 100vh;
  overflow: auto; */

  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  width: 75%;
  /* overflow-y: auto;
  overflow-x: hidden; */
  margin-right: 2rem;
}

.productContainer {
  position: absolute;
  top: 120px;
  bottom: 0;
  left: 0;
  right: 0;
  /* overflow: scroll; */
  /* position: absolute; */
  overflow-y: auto;
  overflow-x: hidden;
}

.productGrid {
  margin: 10px;
}

.categoryTitle {
  width: 100%;
  /* add line bottom */
  border-bottom: 2px solid #ccc;
  padding: 1rem 0 0 1rem;
  font-weight: 700;
  font-size: 1rem;
}
