.imageCard {
  height: 100%;
}

.productImage {
  width: 100%;
  max-height: 150px;
  height: 150px;
  padding: 0.5rem;
  object-fit: contain;
}

.imageName {
  padding: 0 10px 10px 10px;

  font-size: 0.85rem;
  font-weight: 600;
  /* text-align: center; */
  /* line breaks */
  word-wrap: break-word;
}
