.invoiceBase {
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 0;
  /* left: -40; */
  width: 25%;
  /* background-color: rgb(133, 68, 21); */

  -webkit-box-shadow: -3px 3px 7px 1px #0d0d0d77;
  box-shadow: -3px 3px 6px 1px #0d0d0d77;
}

.invoiceArticles {
  height: 77%;
  width: 100%;
  overflow: auto;
  padding: 1rem 0.5rem;
}

.invoiceItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1rem 0.5rem 1rem !important;
  line-height: 1;
}

.invoiceItem > .nameDiv {
  font-size: 0.95rem;
  font-weight: 600;
}

.invoiceItem > .price_amount_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0 0 0;
}

.invoiceItem > .buttonsDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem 0 0 0;
  /* space between items */
  gap: 0.5rem;
}

.invoiceItemPrice {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
}

.invoiceAmount {
  /* add a darker white as background */
  background-color: white;
  width: 50px;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  padding: 4px 0 4px 0;
  border-radius: 5px;
  /* add shadow */
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.336);
}

.invoiceItemButton {
  height: 30px;
  max-height: 30px;
  background-color: #db1a5a;
}

.buttonIcon {
  font-size: 1rem !important;
}

.invoiceMenu {
  height: 23%;
  width: 100%;
  background-color: #b41349;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* add onclick animation */
.invoiceMenu:active {
  /* change background color to something dark */
  background-color: #5c0625;
}

.invoiceTotalText {
  font-size: 1.1rem;
  font-weight: 600;
  color: #f1f1f1;
  padding: 0.5rem 0.5rem 0 0.5rem;
}

.invoiceTotalPrice {
  font-size: 2.5rem;
  font-weight: 500;
  color: #f1f1f1;
  padding: 0.5rem 0.5rem 2rem 0.5rem;
  text-align: right;
  overflow: hidden;
}
