.header {
  width: 100%;
  padding: 0 0px 0 0px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* add bottom shadow */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding-right: 1rem;
}

.sidebar {
  position: absolute;
  top: 40px;
  bottom: 0;
  left: -15vw;
  width: 15vw;
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  justify-content: start;
  row-gap: 1.5rem;

  /* add right shadow */
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.2);
}

.sidebarOpen {
  left: 0;
}

.menuButton {
  height: 100%;
}

.printerStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.printerStatus > i {
  margin-right: 5px;
}
.modalPrinterStatus {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.online_icon {
  color: green;
}
.offline_icon {
  color: red;
}

.printerModal {
  z-index: 10000;
}

.sideMenuButton {
  width: 100% !important;
  font-weight: 600;
  font-size: 1rem;
  block-size: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.logoutButton {
  margin-top: auto;
}
