.productMenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 200px; */
  /* background-color: lightgray; */
  justify-content: space-between;
}

.searchDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  /* background-color: lightgray; */
  margin: 10px 0 0 0;
}

.clearSelectionButton {
  margin-left: 20px;
}

.tabsDiv {
  background-color: lightgray;
  width: 100%;
  margin: 10px 0;
}
