.productCard {
  /* max-height: 250px;
  height: 250px; */
  width: 100%;
  height: 100%;
  /* add shadow */
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.55);
}

/* add on click animation */
.productCard:active {
  transform: scale(0.95);
  transition: transform 0.1s ease-in-out;
  /* small border */
  border: 1px solid #db1a5a;
}

/* disabled product card */
.productCard.disabled {
  opacity: 0.5;
}

.card_content {
}

.product_name {
  padding: 0 10px 10px 10px;

  font-size: 0.85rem;
  font-weight: 600;
  /* text-align: center; */
  /* line breaks */
  word-wrap: break-word;
}

.product_image {
  width: 100%;
  max-height: 150px;
  height: 150px;
  padding: 0.5rem;
  object-fit: contain;
}

.stock_counter {
  /* display this counter at the right top corner */
  position: absolute;
  top: 20px;
  right: 20px;
  /* add a darker white as background */
  background-color: white;
  width: 30px;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  padding: 1px;
  border-radius: 5px;
  /* add shadow */
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.75);
}

.favorite {
  /* display this counter at the right top corner */
  position: absolute;
  top: 5px;
  left: 5px;
}
.favorite_menu {
  z-index: 1000;
}
.favorite_icon {
  font-size: 2rem !important;
  line-height: 1;
  color: #db1a5a;
}
.not_favorite_icon {
  font-size: 2rem !important;
  line-height: 1;
  color: lightgray;
}

.showMenu {
  animation: tilt-shaking 0.5s ease-in-out infinite;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
